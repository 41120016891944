import { Injectable } from '@angular/core';

export interface Evn {
  production: boolean;
  apiUrl: string;
  storageUrl: string;
  socketUrl: string;
}

const environment: Evn = {
  production: false,
  apiUrl: 'https://cen053.ene.cusmart.chula.ac.th/web-api/api/v1',
  storageUrl: 'https://cen053.ene.cusmart.chula.ac.th/storage/axis2/services/FIAPStorage',
  socketUrl: 'http://localhost:3000'
}

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor() { }

  getApiUrl(partUrl?:string) {
    let url = (partUrl || environment.apiUrl);
    let api = environment.apiUrl+url
    return api;
  }

  getStorageUrl(partUrl?:string) {
    let url = (partUrl || environment.storageUrl);
    let api = environment.storageUrl
    return api;
  }

}
